export default {

    blocks:[
        {
            title:'联系方式',
            children:[
                {
                    title:'电话',
                    content:[
                    '400 689 1881',
                    ]
                },
                {
                    title:'邮箱',
                    content:[
                    'nihao@huamei2001.com',
                    ]
                },
                {
                    title:'社交媒体',
                    content:[
                    'nihao@huamei2001.com',
                    ]
                },
            ]
        },
        {
            title:'办公地址',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/temp/%E6%B7%B1%E5%9C%B31.jpg',
            ],
            map:'https://oss.huamei2001.com/temp/%E6%B7%B1%E5%9C%B3%E5%9C%B0%E5%9B%BE.png',
            summary:'',
            children:[
                {
                    title:'深圳办公室',
                    images: [
                        'https://oss.huamei2001.com/temp/%E6%B7%B1%E5%9C%B31.jpg',
                    ],
                    map:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022/maps/深圳地图bw.jpg',
                    content:[
                        '电话：0755-88835988、88316789    ',
                        '地址：深圳市福田区益田路5033号平安金融中心70层    ',
                        '邮编：518035',
                    ]
                },
                {
                    title:'北京办公室',
                    images: [
                        'https://oss.huamei2001.com/temp/%E5%8C%97%E4%BA%AC.jpeg',
                    ],
                    map:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022/maps/北京地图bw.jpg',
                    content:[
                        '电话：010-65228180、65228182    ',
                        '地址：北京市东城区东长安街1号东方广场E2毕马威大楼21层    ',
                        '邮编：100738',
                    ]
                },
                {
                    title:'上海办公室',
                    images: [
                        'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022-1/contact/Jiushi-Renaissance-Mansion_1608_20171219_003.jpg',
                    ],
                    map:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022/maps/上海地图bw.jpg',
                    content:[
                        '电话：021-34619688',
                        '地址：上海市黄浦区淮海中路918号久事复兴大厦22楼FG室',
                        '邮编：200020',
                    ]
                },
                {
                    title:'成都办公室',
                    images: [
                        'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022/maps/%E6%88%90%E9%83%BD%E5%8A%9E%E5%85%AC%E5%AE%A4%E5%A4%96%E9%83%A8.jpg',
                    ],
                    map:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2022/maps/成都地图bw.jpg',
                    content:[
                        '电话：0755-88835988',
                        '地址：成都市高新区天府大道1199号银泰中心3号楼1102室',
                        '邮编：610095',
                    ]
                },
            ]
        },
  ]
}